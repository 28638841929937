import React from 'react'
import PropTypes from 'prop-types'

// Components
import { Link, graphql } from 'gatsby'
import { Layout } from '../components/layout.jsx'
import { SEO } from '../components/seo.jsx'

class TagsPage extends React.Component {
  render () {
    const group = this.props.data.allMarkdownRemark.group
    return (
      <Layout location={this.props.location} title={this.props.data.site.siteMetadata.title}>
        <main>
          <SEO title={this.props.data.site.siteMetadata.title} />
          <ul>
            {group.map(tag => (
              <li key={tag.fieldValue}>
                <Link to={`/tag/${tag.fieldValue}/`} activeClassName='active'>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </ul>
        </main>
      </Layout>
    )
  }
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired
        }).isRequired
      )
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    })
  })
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
